<template>
  <div class="flex lida-header">
    <div class="btn-box">
      <el-button size="mini" type="primary" v-if="btnContentOne"
      @click="handleAdd">
        <span :class="['iconfont', btnOneIcon]"></span>
        <span>{{btnContentOne}}</span>
      </el-button>
      <el-button size="mini" type="primary" v-if="btnContentTwo"
      @click="handleDel">
        <span :class="['iconfont', btnTwoIcon]"></span>
        <span>{{btnContentTwo}}</span>
      </el-button>
      <div>
        <el-checkbox-group v-model="checkList" style="margin-left: 20px;" @change="handleChange">
          <el-checkbox :label="item.name" v-for="(item,index) in checks" :key="index" />
        </el-checkbox-group>
      </div>
    </div>
    <div class="flex">
      <div v-if="showDate">
        <el-date-picker
            v-model="dates"
            type="daterange"
            range-separator="-"
            unlink-panels
            start-placeholder="开始日1"
            end-placeholder="截止日"
            value-format="YYYY-MM-DD"
            @change="handleChange"
        ></el-date-picker>
      </div>
      <div style="margin-left: 20px; width: 320px;" v-if="options">
        <el-select v-model="select" clearable placeholder=""  @change="handleChange">
            <el-option
            v-for="item in options"
            :key="item[optionsValue]"
            :label="item[optionsLabel]"
            :value="item[optionsValue]"
            ></el-option>
        </el-select>
      </div>
      <div class="search-box" style="margin-left: 20px; width: 320px;" v-if="showSearch">
        <el-input v-model="search" placeholder="关键字搜索" clearable @change="handleChange">
          <template #append><span class="iconfont icon-sousuo"></span></template>
        </el-input>
      </div>
    </div>
  </div>
</template>
<script>
// import { computed } from 'vue'
// <!-- XXX 目前使用的双向绑定 没这个必要以后修改 -->
/**
 * @Date 2021-11-15
 * @Desc每个模块头部的功能块
 * btnContentOne [string] 添加按钮的文案
 * btnContentTwo [string] 删除按钮的文案
 * btnOneIcon
 * btnTwoIcon
 * date [Boolean] 双向绑定开始时间结束时间
 * options [array] 下拉框数据
 * optionsLabel [string] 数据label值
 * optionsValue [value] 数据 value 值
 * search [Boolean] 双向绑定搜索关键字
 *
 * selectKey [] 返回下拉框的键名
 * dateKey [] 返回日期的键名
 * checkListKey [] 返回多选的键名
 * searchKey [] 返回搜索的键名
 */
export default {
  props: {
    btnContentOne: String,
    btnContentTwo: String,
    btnOneIcon: {
      type: String,
      default: 'icon-jiahao'
    },
    btnTwoIcon: {
      type: String,
      default: 'icon-shanchu'
    },

    showDate: Boolean,
    showSearch: Boolean,
    options: Array,
    checks: Array,
    optionsLabel: {
        type: String,
        default: 'title'
    },
    optionsValue: {
        type: String,
        default: 'type'
    },
    // 如果想规定返回对象的值
    endDateKey: {
      type: String,
      default: 'endDate'
    },
    startDateKey: {
      type: String,
      default: 'startDate'
    },
    selectKey: {
      type: String,
      default: 'select'
    },
    checkListKey: {
      type: String,
      default: 'checkList'
    },
    searchKey: {
      type: String,
      default: 'search'
    }
  },
  data() {
    return {
      dates: [],
      select: '',
      search: '',
      checkList: []
    }
  },
  methods: {
 // 删除按钮
    handleDel() {
      this.$emit('btnTwo')
    },
    // 新增按钮
    handleAdd() {
      this.$emit('btnOne')
    },
    handleChange() {
      let obj = {}
      // 过滤数据
      if(this.showDate && this.dates) {
        obj[this.startDateKey] = this.dates[0]
        obj[this.endDateKey] = this.dates[1]
      }
      if(this.options && this.select) obj[this.selectKey] = this.select
      if(this.checks && this.checkList) obj[this.checkListKey] = this.checkList
      if(this.showSearch && this.search) obj[this.searchKey] = this.search

      this.$emit('commands', obj)
    }
  }
  // setup(props, context) {
  //     // 日期
  //   const showDate = computed({
  //     get: () => props.date,
  //     set: (v) => {
  //       context.emit(`update:date`, v)
  //     },
  //   })
  //   // 下拉框
  //   const showSelect = computed({
  //       get: () => props.option,
  //       set: (v) => {
  //           context.emit('update:option', v)
  //       }
  //   })
  //   // 搜索框
  //   const showSearch = computed({
  //       get: () => props.search,
  //       set: (v) => {
  //           context.emit('update:search', v)
  //       }
  //   })





  //   return {
  //     showDate,
  //     showSelect,
  //     showSearch,
  //     handleChange,
  //     handleAdd,
  //     handleDel
  //   }
  // }
}
</script>
<style lang="less">

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.card-box {
  padding: 20px;
  box-sizing: border-box;
}

.lida-header {
  .el-button--primary {
    background-color: @highlight-purple !important;
    border-color: @highlight-purple !important;
  }
  .search-box{
    .el-input__suffix{
      top: -5px;
    }
  }
  .el-input-group__append{
    background-color: @highlight-purple !important;
    border-color: @highlight-purple !important;
    color: #fff;
  }
}
</style>


