<template>
  <el-dialog width="1000px" top="10vh">
    <div class="e">
      <div class="e-title">选择报价对象</div>
      <div class="e-content">
        <el-tabs v-model="activeNavs" class="demo-tabs">
          <el-tab-pane
            :label="item.title"
            :name="item.type"
            :key="item.type"
            v-for="item in navs"
          >
          </el-tab-pane>
        </el-tabs>
        <lidaPage
        :queryInfo=" activeNavs === 1?{userType:'1'}:{}"
          :columns="columns"
          :formColumns="formColumns"
          height="300"
          :showResetBtn="false"
          :query="getQuery"
          :span="3"
        >
          <template #operation>
            <lida-table-column label="操作" width="50">
              <template #default="scope">
                <div
                  @click="goOffer(scope.row)"
                  style="color: #D84146;cursor: pointer;"
                >
                  报价
                </div>
              </template>
            </lida-table-column>
          </template>
        </lidaPage>
      </div>
      <div class="e-footer">
        <el-button style="margin-right: 20px;" size="mini" @click="close"
          >取消</el-button
        >
        <el-button
          style="margin-left: 20px;"
          type="primary"
          size="mini"
          @click="confirm"
          >确认</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import offerProfitCompute from "@/common/js/offerProfitCompute.js";
import { mapActions, mapState } from "vuex";
import { ElMessage } from "element-plus";
import moment from "moment";
export default {
  data() {
    return {
      navs: [
        { title: "我的客户", type: 1 },
        { title: "我的供应商", type: 2 },
      ],
      activeNavs: 1,
      value: "", //搜索值
      columns: [
        { label: "客户名称", prop: "userName" },
        { label: "客户电话", prop: "userMobilePhone" },
        { label: "企业名称", prop: "enterpriseName" },
        { label: "法人代表", prop: "legalPersonName" },
        { label: "法人电话", prop: "legalPersonPhone" },
      ],
      formColumns: [
        {
          label: "",
          prop: "wd",
        },
      ],
    };
  },
  computed: {
    getQuery() {
      return this.activeNavs === 1
        ? this.userListPageByShareEnterpriseId
        : this.activeNavs === 2
        ? this.enterpriseListPageByEnterpriseId
        : null;
    },
  },
  methods: {
    ...mapActions("purchaseMudule", [
      "enterpriseListPageByEnterpriseId", //供应商
      "userListPageByShareEnterpriseId", //客户
    ]),
    close() {
      //关闭弹框
      this.$emit("update:modelValue", false);
    },
    goOffer(row) {
      //去报价
      const goodsData = this.$route.params.data;
      // console.log(row.id,'00000000000000000000');
      let rowData = {
        userName: row.userName,
        enterpriseName: row.enterpriseName,
        enterpriseId: row.enterpriseId,
      };
      if (row.id) {
        rowData.userId = row.id;
      } else {
        rowData.userId = row.userId;
      }
      rowData = goodsData ? { ...rowData, data: goodsData } : rowData;
      this.$router.push({
        name: "addOfferOrder",
        params: rowData,
      });
    },
  },
};
</script>

<style scoped lang="less">
.e {
  padding: 20px;

  .e-title {
    font-weight: bold;
    padding-bottom: 10px;

    span {
      color: @dh-color;
      margin: 0 5px;
    }
  }

  .e-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .e-content {
    .e-search {
      display: flex;
      align-items: center;
    }
  }
}
</style>
